// Esse arquivo possui todas as variáveis globais da aplicação

// Templates ativos (para ativar inserir active: true)
let fastTemplates = [
  {        
    folder: "fastead",
    domain: "fastead.com",
    css: () => require(`@/assets/scss/app.scss`),
    fastLogoPrincipal: getImportAsync("assets/images/logo_horizontal.png"),
    fastLoading: getImportAsync("assets/images/loading_aguarde.png"),
    whatsExterno: "+5551998595714",
    urlRedirectLogout: "/?login",
    idPlataformaExterno: 1287,
    components: {}, 
    active: true,                   
  },
  {    
    /*
    folder: "fastead",
    domain: "iuea.fastead.com",
    css: () => require(`@/assets/iuea/app.css`),
    fastLogoPrincipal: getImportAsync("assets/iuea/images/logo_horizontal.png"),
    fastLoading: getImportAsync("assets/iuea/images/logo_horizontal.png"),
    whatsExterno: "+5511994478241",
    urlRedirectLogout: "https://iuea.com.br/login/",
    idPlataformaExterno: 1287,
    components: {},     
    */   
  },
  {   
    /*
    folder: "fastead",
    domain: "eadiwinstar.com.br",
    css: () => require(`@/assets/scss/app.scss`),
    fastLogoPrincipal: getImportAsync("assets/eadiwinstar/images/logo_horizontal.png"),
    fastLoading: getImportAsync("assets/eadiwinstar/images/logo_horizontal.png"),
    whatsExterno: "+5547999342082",
    urlRedirectLogout: "/?login",
    idPlataformaExterno: 2425,
    idPlataformaMaeFixa: 2425,
    components: {
      HomeExternoHeader: "eadiwinstar/HomeExternoHeader.vue",
      HomeExternoContent: "eadiwinstar/HomeExternoPrincipal.vue",
      HomeExternoFooter: "eadiwinstar/HomeExternoFooter.vue",
    },   
    */    
  },
];

let fastTemplateSelected = fastTemplates.filter((e) => e.active);

// Aplica CSS
fastTemplateSelected[0].css();

// Ativa/desativa produção
let fastAmbiente = [
  {
    ambiente: "localhost",
    //endApiFastEad: "https://localhost:7102/",
    endApiFastEad: "https://devapi.fastead.com/",
    endApiConcurseiro:  "https://devapi.fastead.com/",
    endFastEad: "https://localhost:8080/", 
    active: window.location.host.indexOf('localhost') != '-1' ? true : false
  },
  {
    ambiente: "dev",
    endApiFastEad: "https://devapi.fastead.com/",
    endApiConcurseiro: "https://devapi.fastead.com/",
    endFastEad: "https://dev.fastead.com/",   
    active: window.location.host == 'dev.fastead.com' ? true : false
  },
  {
    ambiente: "hom",
    endApiFastEad: "https://homapi.fastead.com/",
    endApiConcurseiro: "https://homapi.concurseiro.com/",
    endFastEad: "https://hom.fastead.com/",    
    active: window.location.host == 'hom.fastead.com' ? true : false
  },
  {
    ambiente: "prod",
    endApiFastEad: "https://api.fastead.com/",
    endApiConcurseiro: "https://api.concurseiro.com/",
    endFastEad: `https://${fastTemplateSelected[0].domain}/`,    
    active: window.location.host == 'fastead.com' || window.location.host == 'www.fastead.com' ? true : false
  },
];

function getImportAsync(path) {
  return () => {
    let name = path.split(".")[0];
    let ext = path.split(".").pop();
    switch (ext) {
      case "png":
        return import(`@/${name}.png`);
        break;
      case "jpg":
        return import(`@/${name}.jpg`);
        break;
      case "jpeg":
        return import(`@/${name}.jpeg`);
        break;
      case "vue":
        return import(`@/${name}.vue`);
        break;
      default:
        break;
    }
  };
}

// Variáveis de sessão
const fastSessaoUsuario = "fastSessaoUsuario";
const fastSessaoToken = "fastSessaoToken";
const fastSessaoPlataforma = "fastSessaoPlataforma";
const fastSessaoPermissoes = "fastSessaoPermissoes";
const fastSessaoCarrinho = "fastSessaoCarrinho";
const fastSessaoMinhasPlataformas = "fastSessaoMinhasPlataformas";
const fastSessaoMinhasPlataformasLista = "fastSessaoMinhasPlataformasLista";
const fastSessaoUsuarioAdministra = "fastSessaoUsuarioAdministra";
const fastSessaoUsuarioAdminFast = "fastSessaoUsuarioAdminFast";
const fastSessaoCheckoutFinal = "fastSessaoCheckoutFinal";
const fastSessaoPlataformaPersonalizacao = "fastSessaoPlataformaPersonalizacao";
const fastSessaoPlataformaModulos = "fastSessaoPlataformaModulos";
const fastSessaoSecretariaMatricula = "fastSessaoSecretariaMatricula";

let fastAmbienteSelected = fastAmbiente.filter((e) => e.active);
export let settings = {
  endApiFastEad: fastAmbienteSelected[0].endApiFastEad,
  endFastEad: fastAmbienteSelected[0].endFastEad,
  endApiConcurseiro: fastAmbienteSelected[0].endApiConcurseiro,
  fastTemplate: fastTemplateSelected[0].folder,
  fastLogoPrincipal: fastTemplateSelected[0].fastLogoPrincipal,
  fastLoading: fastTemplateSelected[0].fastLoading,
  fastDomain: fastTemplateSelected[0].domain,
  fastImagesFolder: fastTemplateSelected[0].imagesFolder,
  fastTelefoneContato: fastTemplateSelected[0].whatsExterno,
  urlRedirectLogout: fastTemplateSelected[0].urlRedirectLogout,
  fastIdPlataformaMaeFixa: fastTemplateSelected[0].idPlataformaMaeFixa,
  fastIdPlataformaExterno: fastTemplateSelected[0].idPlataformaExterno,
  fastComponents: fastTemplateSelected[0].components,  
  fastSessaoUsuario: fastSessaoUsuario,
  fastSessaoToken: fastSessaoToken,
  fastSessaoPlataforma: fastSessaoPlataforma,
  fastSessaoPermissoes: fastSessaoPermissoes,
  fastSessaoCarrinho: fastSessaoCarrinho,
  fastSessaoMinhasPlataformas: fastSessaoMinhasPlataformas,
  fastSessaoMinhasPlataformasLista: fastSessaoMinhasPlataformasLista,
  fastSessaoUsuarioAdministra: fastSessaoUsuarioAdministra,
  fastSessaoUsuarioAdminFast: fastSessaoUsuarioAdminFast,
  fastSessaoCheckoutFinal: fastSessaoCheckoutFinal,
  fastSessaoPlataformaPersonalizacao: fastSessaoPlataformaPersonalizacao,
  fastSessaoPlataformaModulos: fastSessaoPlataformaModulos,
  fastSessaoSecretariaMatricula: fastSessaoSecretariaMatricula
};